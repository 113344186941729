import React, { useState, useEffect } from 'react';
import axios from 'axios';
import submitFormData from '../../utils/submitFormData';
import dayjs from 'dayjs'; // Assuming dayjs is already imported

interface PresenceCoverageProps {
  dateStr: string; // Date string passed as an argument (YYYY-MM-DD)
  deviceIds: string[]; // List of device IDs passed as an argument
}

interface DeviceCoverage {
  presencePercentage: string;
  totalVideos: number;
  expectedVideos: number;
  missingVideos: number;
}

const PresenceCoverage: React.FC<PresenceCoverageProps> = ({ dateStr, deviceIds }) => {
  const [presenceCoverage, setPresenceCoverage] = useState<{ [key: string]: DeviceCoverage | null }>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const formatTimestamp = (timestamp: string) => {
    return dayjs(timestamp).format('MMM D, YYYY');
  };

  // Automatically fetch data when dateStr or deviceIds change
  useEffect(() => {
    handleFetchData();
  }, [dateStr, deviceIds]);

  const handleFetchData = async () => {
    if (!deviceIds || deviceIds.length === 0 || !dateStr) {
      setError('Please provide both device IDs and date.');
      return;
    }

    setIsLoading(true);
    setError(null); // Reset any previous errors

    try {
      const response = await submitFormData(
        { deviceIds: deviceIds, dateStr: dateStr },
        'presence-coverage'
      );
      console.log("RESPONSE:", response);

      if (response && response.presence_coverage) {
        // Store the presence coverage for each device in the state
        const newCoverage: { [key: string]: DeviceCoverage | null } = {};
        deviceIds.forEach((deviceId) => {
          const deviceData = response.presence_coverage[deviceId];
          if (deviceData) {
            newCoverage[deviceId] = {
              presencePercentage: deviceData.presence_percentage || '0%',
              totalVideos: deviceData.total_videos || 0,
              expectedVideos: deviceData.expected_videos || 0,
              missingVideos: deviceData.missing_videos || 0,
            };
          } else {
            newCoverage[deviceId] = null; // Handle devices with no data
          }
        });
        setPresenceCoverage(newCoverage);
      } else {
        setError('No data received from the server.');
      }
    } catch (err) {
      console.error('Error while fetching presence coverage:', err);
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 401) {
          setError('Session expired. Please log in again.');
        } else {
          setError('An error occurred while fetching the data.');
        }
      } else {
        setError('An unexpected error occurred.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-6 border rounded-lg shadow-md w-full bg-white">
      {isLoading && <div className="text-center mb-4">Loading...</div>}

      {dateStr && <div className="text-center mb-3 text-xl font-bold">{formatTimestamp(dateStr)}</div>}

      {error && <div className="text-center text-red-500 mb-4">{error}</div>}

      {/* Table for displaying the data */}
      {!isLoading && !error && presenceCoverage && (
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto border-collapse">
            <thead>
              <tr className="border-b">
                <th className="px-4 py-2 text-left text-lg font-medium text-gray-600">Device ID</th>
                <th className="px-4 py-2 text-left text-lg font-medium text-gray-600">Presence Coverage (%)</th>
                <th className="px-4 py-2 text-left text-lg font-medium text-gray-600">Total Videos</th>
                <th className="px-4 py-2 text-left text-lg font-medium text-gray-600">Expected Videos</th>
                <th className="px-4 py-2 text-left text-lg font-medium text-gray-600">Missing Videos</th>
              </tr>
            </thead>
            <tbody>
              {deviceIds.map((deviceId) => (
                <tr key={deviceId} className="border-b">
                  <td className="px-4 py-2 text-gray-700">{deviceId}</td>
                  <td className="px-4 py-2 text-gray-700">
                    {presenceCoverage[deviceId]?.presencePercentage || 0}
                  </td>
                  <td className="px-4 py-2 text-gray-700">
                    {presenceCoverage[deviceId]?.totalVideos || 0}
                  </td>
                  <td className="px-4 py-2 text-gray-700">
                    {presenceCoverage[deviceId]?.expectedVideos || 0}
                  </td>
                  <td className="px-4 py-2 text-gray-700">
                    {presenceCoverage[deviceId]?.missingVideos || 0}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* No Data fallback */}
      {!isLoading && !error && Object.keys(presenceCoverage).length === 0 && (
        <div className="text-center text-gray-500">No data available for the selected devices.</div>
      )}
    </div>
  );
};

export default PresenceCoverage;
