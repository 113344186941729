import React, { useState, SetStateAction, Dispatch, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import dayjs from 'dayjs';
import FormWrapper from './FormWrapper';
import DateTimePickerComponent from '../Forms/DateTimePicker';
import SelectDropdown from './SelectDropdown';
import SubmitButton from './SubmitButton';
import { SelectChangeEvent } from '@mui/material/Select';
import submitFormData from '../../utils/submitFormData';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FormData } from '../../pages/Videos';

interface DataFormProps {
  coverage: any[];
  setCoverage: React.Dispatch<React.SetStateAction<any[]>>;
  setVideos: Dispatch<SetStateAction<any[]>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  setIsQueryEmpty: Dispatch<SetStateAction<boolean>>;
  vehicles: string[]
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  formData: FormData;
}

const DataForm: React.FC<DataFormProps> = ({ setVideos, setIsLoading, isLoading, setIsQueryEmpty, vehicles, setFormData, formData, coverage, setCoverage }) => {
  const now = dayjs().tz("America/Toronto");
  const midnight = now.startOf('day');
  const [isValid, setIsValid] = useState(false);
  const navigate = useNavigate()

  const handleDateChange = (field: keyof FormData) => (value: dayjs.Dayjs | null) => {
    setFormData({ ...formData, [field]: value ? value.toISOString() : '' });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, 'generateCsvs': event.target.checked });
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    setFormData({ ...formData, vehicle: [event.target.value as string] });
  };

  const handleSelectFilterChange = (event: SelectChangeEvent) => {
    setFormData({ ...formData, filterBy: event.target.value as string });
  };

  function checkIsValid(){
    if (formData.from !== '' && formData.to !== ''){
      return true
    }
    else {
      return false
    }
  }

  useEffect(() => {
    const valid = checkIsValid()
    setIsValid(valid);
  },[formData]);


  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    try {
      setIsLoading(true);
      if (formData.vehicle[0] === "All"){
        let new_vehicles = vehicles.slice(1)
        const response = await submitFormData({ ...formData, vehicle: new_vehicles }, 'api-video');
        if (response) {
          setVideos(response.videos);
          setCoverage(response.coverage)
          if (response.videos.length === 0){
            setIsQueryEmpty(true)
          }
          else {
            setIsQueryEmpty(false)
          }
          setIsLoading(false)
        } else {
          console.error('No response received');
          setIsLoading(false)
        }
      }
      else {
        const response = await submitFormData(formData, 'api-video');
        if (response) {
          setVideos(response.videos);
          setCoverage(response.coverage)
          if (response.videos.length === 0){
            setIsQueryEmpty(true)
          }
          else {
            setIsQueryEmpty(false)
          }
          setIsLoading(false)
        } else {
          console.error('No response received');
          setIsLoading(false)
        }
      }
    } catch (error) {
      if(axios.isAxiosError(error)){
        if(error.response && error.response.status == 401){
          alert('Session has expired, please login again.')
          navigate('/login')
        }
      }else{
        console.error('Submission failed', error);
      }
      setIsLoading(false);
    }
  };

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <DateTimePickerComponent
        defaultValue={midnight}
        label="From"
        value={formData.from}
        onChange={handleDateChange('from')}
      />
      <DateTimePickerComponent
        defaultValue={now}
        label="To"
        value={formData.to}
        onChange={handleDateChange('to')}
      />
      <SelectDropdown
        title={'Vehicle'}
        value={formData.vehicle[0]}
        onChange={handleSelectChange}
        options={vehicles}
      />
      <SelectDropdown
        title={'Filter By'}
        value={formData.filterBy}
        onChange={handleSelectFilterChange}
        options={['All', 'Liked', 'Review', 'Fleetedge']}
      />
      <SubmitButton label={'Submit'} isValid={isValid} isLoading={isLoading} />
    </FormWrapper>
  );
};

export default DataForm;