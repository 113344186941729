import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

interface TimeRange {
  start_time: string;
  end_time: string;
}

interface ActiveStopSpan {
  start_time: string;
  end_time: string;
  stop_number?: number;
}

interface BarcodeData {
  barcode: string;
  vapr_seen_periods: TimeRange[];
  active_stop_spans: ActiveStopSpan[];
  red_periods: RedPeriod[];
  is_ov: boolean;
}

interface ActiveStop {
  start_time: string;
  end_time: string;
  stop_number: number;
}

interface RedPeriod {
  start_time: string;
  end_time: string;
  stop_number: number;
}

export interface PlotData {
  barcodes: BarcodeData[];
  driving_periods: [string, string][];
  presence_intervals: TimeRange[];
  outages: [string, string][];
  video_coverage: TimeRange[];
  active_stops: ActiveStop[];
}

interface PlotVisualizationProps {
  plotData: PlotData | null | undefined;
  isOpen: boolean;
  onClose: () => void;
}

const PlotVisualization: React.FC<PlotVisualizationProps> = ({ plotData, isOpen, onClose }) => {
  const [plotlyData, setPlotlyData] = useState<any[]>([]);
  const [summaryData, setSummaryData] = useState<any[]>([]);
  const [xRange, setXRange] = useState<[string, string] | undefined>(undefined);
  const [filterMode, setFilterMode] = useState<'OV' | 'Regular' | 'Both'>('Both'); // OV, Regular, or Both
  const [barcodeLayout, setBarcodeLayout] = useState<{
	xaxis: {
	  type: 'date';
	  range: [string, string] | undefined; // Allow null for initial state
	  autorange: boolean;
	};
	yaxis: {
	  title: string;
	  tickvals: number[];
	  ticktext: string[];
	  fixedrange: boolean;
	};
	hovermode: 'x unified';
	margin: { l: number; r: number; t: number; b: number };
	height: number;
	showlegend: boolean;
	dragmode: 'zoom';
  }>({
	xaxis: {
	  type: 'date',
	  range: xRange,
	  autorange: false,
	},
	yaxis: {
	  title: 'Barcodes',
	  tickvals: [],
	  ticktext: [],
	  fixedrange: true,
	},
	hovermode: 'x unified',
	margin: { l: 125, r: 50, t: 25, b: 25 },
	height: 500,
	showlegend: false,
	dragmode: 'zoom',
  });
  

  useEffect(() => {
    if (!plotData) return;

	// Determine the default xRange from driving_periods
	const drivingStart = plotData.driving_periods[0]?.[0];
	const drivingEnd = plotData.driving_periods[plotData.driving_periods.length - 1]?.[1];

	const defaultXRange: [string, string] = [drivingStart, drivingEnd];

    const barcodeTraces: any[] = [];
    const summaryTraces: any[] = [];
    let yIndex = 1;

    // Filter barcodes based on filterMode
    const filteredBarcodes = plotData.barcodes.filter((barcode) => {
      if (filterMode === 'OV') return barcode.is_ov;
      if (filterMode === 'Regular') return !barcode.is_ov;
      return true; // 'Both'
    });

    // Top Plot: Barcode Rows
    filteredBarcodes.forEach((barcode) => {
      const barcodeYIndex = yIndex++;
	  console.log("Number of barcodes: ", barcodeYIndex - 1)

      // VAPR Seen Periods
      barcodeTraces.push(
        ...barcode.vapr_seen_periods.map(({ start_time, end_time }) => ({
          x: [start_time, end_time],
          y: [barcodeYIndex, barcodeYIndex],
          mode: 'lines',
          line: { color: 'blue', width: 10 },
          hoverinfo: 'none',
          showlegend: false,
        }))
      );

      // Active Stop Spans
      barcodeTraces.push(
        ...barcode.active_stop_spans.map(({ start_time, end_time, stop_number }) => ({
          x: [start_time, end_time],
          y: [barcodeYIndex, barcodeYIndex],
          mode: 'lines+text', // Display text directly on the plot
          line: { color: 'green', width: 10 },
          textposition: 'top center',
          hoverinfo: 'none',
          showlegend: false,
        }))
      );

      // Overlap spans
      barcodeTraces.push(
        ...barcode.red_periods.map(({ start_time, end_time, stop_number }) => ({
          x: [start_time, end_time],
          y: [barcodeYIndex, barcodeYIndex],
          mode: 'lines+text', // Display text directly on the plot
          line: { color: 'red', width: 10 },
          textposition: 'top center',
          hoverinfo: 'none',
          showlegend: false,
        }))
      );
    });

    // Bottom Plot: Summary Rows
    summaryTraces.push(
      ...plotData.driving_periods.map(([start, end]) => ({
        x: [start, end],
        y: [1, 1],
        mode: 'lines',
        line: { color: 'black', width: 10 },
        name: 'Drive/Park',
        hoverinfo: 'text',
        hovertext: `${start} - ${end}`,
        showlegend: false,
      }))
    );

    summaryTraces.push(
      ...plotData.presence_intervals.map(({ start_time, end_time }) => ({
        x: [start_time, end_time],
        y: [2, 2],
        mode: 'lines',
        line: { color: 'purple', width: 10 },
        name: 'Presence',
        hoverinfo: 'text',
        hovertext: `${start_time} - ${end_time}`,
        showlegend: false,
      }))
    );

    summaryTraces.push(
		...plotData.outages.map(([start_time, end_time]) => ({
		  x: [start_time, end_time],
		  y: [3, 3],
		  mode: 'lines',
		  line: { color: 'pink', width: 10 },
		  name: 'Outage',
		  hoverinfo: 'text',
		  hovertext: `${start_time} - ${end_time}`,
		  showlegend: false,
		}))
	  );

    summaryTraces.push(
      ...plotData.video_coverage.map(({ start_time, end_time }) => ({
        x: [start_time, end_time],
        y: [4, 4],
        mode: 'lines',
        line: { color: 'orange', width: 10 },
        name: 'Video Coverage',
        hoverinfo: 'text',
        hovertext: `${start_time} - ${end_time}`,
        showlegend: false,
      }))
    );

    summaryTraces.push(
      ...plotData.active_stops.map(({ start_time, end_time, stop_number }) => ({
        x: [start_time, end_time],
        y: [5, 5], // Active Stops row
        mode: 'lines+text', // Show stop number as text
        line: { color: `hsl(${(stop_number * 40) % 360}, 70%, 50%)`, width: 10 },
        textposition: 'top center',
        hoverinfo: 'text',
        hovertext: `Stop #${stop_number}`,
        showlegend: false,
      }))
    );

	const stop_number_list = plotData.active_stops.map(({ start_time, end_time, stop_number }) => stop_number)
	const unique_stop_numbers = new Set(stop_number_list).size;
	console.log("Number of unique stop numbers: ", unique_stop_numbers)

    // Update plot data and layout dynamically
    setPlotlyData(barcodeTraces);
    setSummaryData(summaryTraces);

    const tickvals = Array.from({ length: filteredBarcodes.length }, (_, i) => i + 1);
    const ticktext = filteredBarcodes.map((b) => b.barcode);

	setBarcodeLayout((prevLayout) => ({
		...prevLayout,
		yaxis: {
		  ...prevLayout.yaxis,
		  tickvals,
		  ticktext,
		},
		xaxis: {
			...prevLayout.xaxis,
			autorange: false,
			range: defaultXRange
		},
		height: filteredBarcodes.length ? filteredBarcodes.length * 18 : 500, // Adjust height dynamically
	  }));
	  

  }, [plotData]);

  useEffect(() => {
	if (isOpen){
		setFilterMode("Both")
		setXRange(undefined);
	}
  },[isOpen])

  useEffect(() => {
    if (!plotData) return;

    const barcodeTraces: any[] = [];
    const summaryTraces: any[] = [];
    let yIndex = 1;

    // Filter barcodes based on filterMode
    const filteredBarcodes = plotData.barcodes.filter((barcode) => {
      if (filterMode === 'OV') return barcode.is_ov;
      if (filterMode === 'Regular') return !barcode.is_ov;
      return true; // 'Both'
    });

    // Top Plot: Barcode Rows
    filteredBarcodes.forEach((barcode) => {
      const barcodeYIndex = yIndex++;
	  console.log("Number of barcodes: ", barcodeYIndex - 1)

      // VAPR Seen Periods
      barcodeTraces.push(
        ...barcode.vapr_seen_periods.map(({ start_time, end_time }) => ({
          x: [start_time, end_time],
          y: [barcodeYIndex, barcodeYIndex],
          mode: 'lines',
          line: { color: 'blue', width: 10 },
          hoverinfo: 'none',
          showlegend: false,
        }))
      );

      // Active Stop Spans
      barcodeTraces.push(
        ...barcode.active_stop_spans.map(({ start_time, end_time, stop_number }) => ({
          x: [start_time, end_time],
          y: [barcodeYIndex, barcodeYIndex],
          mode: 'lines+text', // Display text directly on the plot
          line: { color: 'green', width: 10 },
          textposition: 'top center',
          hoverinfo: 'none',
          showlegend: false,
        }))
      );

      // Overlap spans
      barcodeTraces.push(
        ...barcode.red_periods.map(({ start_time, end_time, stop_number }) => ({
          x: [start_time, end_time],
          y: [barcodeYIndex, barcodeYIndex],
          mode: 'lines+text', // Display text directly on the plot
          line: { color: 'red', width: 10 },
          textposition: 'top center',
          hoverinfo: 'none',
          showlegend: false,
        }))
      );
    });

    // Bottom Plot: Summary Rows
    summaryTraces.push(
      ...plotData.driving_periods.map(([start, end]) => ({
        x: [start, end],
        y: [1, 1],
        mode: 'lines',
        line: { color: 'black', width: 10 },
        name: 'Drive/Park',
        hoverinfo: 'text',
        hovertext: `${start} - ${end}`,
        showlegend: false,
      }))
    );

    summaryTraces.push(
      ...plotData.presence_intervals.map(({ start_time, end_time }) => ({
        x: [start_time, end_time],
        y: [2, 2],
        mode: 'lines',
        line: { color: 'purple', width: 10 },
        name: 'Presence',
        hoverinfo: 'text',
        hovertext: `${start_time} - ${end_time}`,
        showlegend: false,
      }))
    );

    summaryTraces.push(
		...plotData.outages.map(([start_time, end_time]) => ({
		  x: [start_time, end_time],
		  y: [3, 3],
		  mode: 'lines',
		  line: { color: 'pink', width: 10 },
		  name: 'Outage',
		  hoverinfo: 'text',
		  hovertext: `${start_time} - ${end_time}`,
		  showlegend: false,
		}))
	  );

    summaryTraces.push(
      ...plotData.video_coverage.map(({ start_time, end_time }) => ({
        x: [start_time, end_time],
        y: [4, 4],
        mode: 'lines',
        line: { color: 'orange', width: 10 },
        name: 'Video Coverage',
        hoverinfo: 'text',
        hovertext: `${start_time} - ${end_time}`,
        showlegend: false,
      }))
    );

    summaryTraces.push(
      ...plotData.active_stops.map(({ start_time, end_time, stop_number }) => ({
        x: [start_time, end_time],
        y: [5, 5], // Active Stops row
        mode: 'lines+text', // Show stop number as text
        line: { color: `hsl(${(stop_number * 40) % 360}, 70%, 50%)`, width: 10 },
        textposition: 'top center',
        hoverinfo: 'text',
        hovertext: `Stop #${stop_number}`,
        showlegend: false,
      }))
    );

    // Update plot data and layout dynamically
    setPlotlyData(barcodeTraces);
    setSummaryData(summaryTraces);

    const tickvals = Array.from({ length: filteredBarcodes.length }, (_, i) => i + 1);
    const ticktext = filteredBarcodes.map((b) => b.barcode);

	setBarcodeLayout((prevLayout) => ({
		...prevLayout,
		yaxis: {
		  ...prevLayout.yaxis,
		  tickvals,
		  ticktext,
		},

		height: filteredBarcodes.length ? filteredBarcodes.length * 18 : 500, // Adjust height dynamically
	  }));
	  

  }, [filterMode]);



  const handleRelayout = (event: any) => {
    if (event['xaxis.range[0]'] && event['xaxis.range[1]']) {
      setXRange([event['xaxis.range[0]'], event['xaxis.range[1]']]);
    } else if (event['xaxis.autorange']) {
		// Determine the default xRange from driving_periods
		if (plotData){
			const drivingStart = plotData.driving_periods[0]?.[0];
			const drivingEnd = plotData.driving_periods[plotData.driving_periods.length - 1]?.[1];
			const defaultXRange: [string, string] = [drivingStart, drivingEnd];
			setBarcodeLayout((prevLayout) => ({
				...prevLayout,

				xaxis: {
					...prevLayout.xaxis,
					autorange: false,
					range: defaultXRange
				},
			  }));
			setXRange(defaultXRange);
			}
    }
  };

  if (!isOpen || !plotData) {
    return null;
  }

  return (
    <div style={modalStyles.overlay}>
      <div style={modalStyles.modal}>
        <button style={modalStyles.closeButton} onClick={onClose}>
          &times;
        </button>
        <div className='space-x-3'>
		<button className={`rounded-md my-2 px-2 py-1 bg-blue-500 text-white ${filterMode === 'Both' && `bg-gray-400`}`} onClick={() => setFilterMode('Both')}>
            All
          </button>
		  <button className={`rounded-md my-2 px-2 py-1 bg-blue-500 text-white ${filterMode === 'Regular' && `bg-gray-400`}`} onClick={() => setFilterMode('Regular')}>
            Regular
          </button>
          <button className={`rounded-md my-2 px-2 py-1 bg-blue-500 text-white ${filterMode === 'OV' && `bg-gray-400`}`} onClick={() => setFilterMode('OV')}>
            OV
          </button>
        </div>
        <div style={modalStyles.mainContent}>
          <Plot
		    key={xRange ? xRange.join('-') : 'default'}
            config={{
              displayModeBar: true,
              modeBarButtonsToRemove: ['lasso2d', 'zoomIn2d', 'zoomOut2d', 'resetScale2d', 'autoScale2d', 'toImage', 'select2d'],
              displaylogo: false,
            }}
            data={plotlyData}
            layout={barcodeLayout}
            useResizeHandler={false}
            style={{ width: '100%', height: '100%' }}
            onRelayout={handleRelayout}
          />
        </div>
        <div style={modalStyles.fixedBottom}>
          <Plot
            config={{
              displayModeBar: false,
              displaylogo: false,
            }}
            data={summaryData}
            layout={{
              xaxis: {
                type: 'date' as const,
                range: xRange || undefined,
                fixedrange: true,
              },
              yaxis: {
                title: '',
                tickvals: [1, 2, 3, 4, 5],
                ticktext: ['Drive/Park', 'Presence', 'Bunny Outages', 'Video Coverage', 'Active Stops'],
                fixedrange: true,
              },
              margin: { l: 125, r: 50, t: 10, b: 50 },
              height: 200,
              showlegend: false,
            }}
            useResizeHandler={false}
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      </div>
    </div>
  );
};

const modalStyles = {
  overlay: {
    position: 'fixed' as const,
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    position: 'relative' as const,
    backgroundColor: 'white',
    borderRadius: '8px',
    width: '90%',
    height: '90%',
    display: 'flex',
    flexDirection: 'column' as const,
    padding: '20px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.25)',
  },
  closeButton: {
    position: 'absolute' as const,
    top: '10px',
    right: '10px',
    background: 'transparent',
    border: 'none',
    fontSize: '24px',
    cursor: 'pointer',
  },
  mainContent: {
    flex: 1,
    overflowY: 'auto' as const,
    overflowX: 'hidden' as const,
    marginBottom: '10px',
    padding: '5px',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    border: '1px solid #ddd',
  },
  fixedBottom: {
    height: '200px',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    padding: '5px',
    border: '1px solid #ccc',
    overflowY: 'hidden' as const,
	overflowX: 'hidden' as const
  },
};

export default PlotVisualization;
